import React, { useState } from 'react';
import './style.css';

import { CgMenuLeft } from "react-icons/cg";
import {Link} from "react-router-dom";
// import WhiteNavbarLogo from '../../assets/Images/whitenavbarLogo.png';
import HeaderLogo from '../../assets/Images/headerlogo.png';
import Drawers from '../drawer/drawer';

const WhiteNavbar = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const showDrawer = () => {
    setOpenDrawer(true);
  };

  const onClose = () => {
    setOpenDrawer(false);
  };
  return (
    <div className={'navbarFixed ContactScreennavbarfixedBgcolor'}>
      {openDrawer ? <Drawers visible={openDrawer} onClose={onClose} /> : null}
      <div className="container">
        <div className="row navbar">
          <Link to="/" className="col-4">
            <img className="Headerlogo" src={HeaderLogo} /> 
          </Link>
          <div className="col-8">
            <div className="navbarContainer">
              <Link to="/" className="whitenavhaederlinks" >
                Home
              </Link>
              <Link to="/about-us" className="whitenavhaederlinks">
                About Us
              </Link>
              <Link to="/gallery" className="whitenavhaederlinks">
                Gallery
              </Link> 
              <Link to="/projects" className="whitenavhaederlinks">
                Projects
              </Link>
              <Link to="/contact-us" className="whitenavhaederlinks"> 
                Contact Us
              </Link>
              <button className="buttonContainer">
                <Link to="/donate" className="buttonText"> 
                  Donate 
                </Link>
              </button>
            </div>
            <div className="navbarNone">
              <div className="btnEnd">
              <button onClick={showDrawer} className="menubtnContainer">
                  <a href="#" className="menubtnText">
                  <CgMenuLeft/>
                  </a>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhiteNavbar;
