import React, { Component } from 'react';
import Slider from 'react-slick';

import './style.css';

import SliderImage from '../../assets/Images/Projectimage.png';
function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block', background: '#F2F2F2' }}
      onClick={onClick}
    ></div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
       
      style={{ ...style, display: 'block', background: '#F2F2F2' }}
      onClick={onClick}
    />
  );
}
export default class SimpleSlider extends Component {
  render() {
    const settings = {
      dots: true, 
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />, 
    };
    return (
      <div className="container">
        <Slider {...settings}>
          <div>
            <img style={{ width: '100%', borderRadius: '8px',   }} src={SliderImage} />
          </div>
          {/* <div>
            <img style={{ width: '100%', borderRadius: '8px',  }} src={SliderImage} />
          </div> */}
        </Slider>
      </div>
    );
  }
}
