import React from 'react';
import { Modal } from 'antd';
import { AiOutlineCheck } from 'react-icons/ai';
import './style.css';

const SuccessModal = ({ visible, onClose, successMessage, thankuMsg }) => {
  return (
    <Modal visible={visible} closable={false} onClose={onClose} footer={false}>
      <div className="centerTickCircle">
        <div className="tickCircle">
          <p className="tickCirlceText">
            <AiOutlineCheck color="#E2AF46" fontSize="40px" fontWeight="bold" border="4px solid red" />
          </p>
        </div>
      </div>
      <p className="modalOneHeading">{successMessage}</p>
      <p className="modalOneParagraph">{thankuMsg}</p>
      <div style={{ cursor: 'pointer' }} onClick={onClose} className="modalHomepageBtn">
        <div className="modalHomapageBtnName">Back to Homepage</div>
      </div>
    </Modal>
  );
};

export default SuccessModal;
