import React, { useState } from 'react';
import { Modal } from 'antd';
import { Slide } from 'react-slideshow-image'
import 'react-slideshow-image/dist/styles.css'
import './style.css';
import Gallery from 'react-grid-gallery';

import GalleryImage from '../../assets/Images/Galleryimage.png';
import ImageOne from '../../assets/Images/Imageone.JPG';
import ImageTwo from '../../assets/Images/Imagetwo.jpeg';
import ImageThree from '../../assets/Images/Imagethree.jpeg';
import ImageFour from '../../assets/Images/Imagefour.jpeg';
import ImageFive from '../../assets/Images/Imagefive.JPG';
import ImageSix from '../../assets/Images/Imagesix.jpeg';
import ImageSeven from '../../assets/Images/Imageseven.jpeg';
import ImageEight from '../../assets/Images/Imageeight.jpeg';
import ImageNine from '../../assets/Images/Imagenine.jpeg';
import ImageTen from '../../assets/Images/Imageten.jpeg';
import ImageEleven from '../../assets/Images/Imageeleven.JPG';
import ImageTwelve from '../../assets/Images/Imagetwelve.jpeg';
import ImageThirteen from '../../assets/Images/Imagethirteen.jpeg';
import SvgImage from '../../assets/Images/checking.svg'

const IMAGES =
  [{
    src: ImageOne,
    thumbnail: ImageOne,
    thumbnailWidth: 500,
    thumbnailHeight: 500,
  },
  {
    src: ImageTwo,
    thumbnail: ImageTwo,
    thumbnailWidth: 500,
    thumbnailHeight: 500,
  },

  {
    src: ImageThree,
    thumbnail: ImageThree,
    thumbnailWidth: 320,
    thumbnailHeight: 212
  },
  {
    src: ImageFour,
    thumbnail: ImageFour,
    thumbnailWidth: 320,
    thumbnailHeight: 174,
  },
  {
    src: ImageFive,
    thumbnail: ImageFive,
    thumbnailWidth: 320,
    thumbnailHeight: 212,

  },

  {
    src: ImageSix,
    thumbnail: ImageSix,
    thumbnailWidth: 320,
    thumbnailHeight: 212
  },
  {
    src: ImageSeven,
    thumbnail: ImageSeven,
    thumbnailWidth: 320,
    thumbnailHeight: 174,
  },
  {
    src: ImageEight,
    thumbnail: ImageEight,
    thumbnailWidth: 320,
    thumbnailHeight: 212,
  },

  {
    src: ImageNine,
    thumbnail: ImageNine,
    thumbnailWidth: 320,
    thumbnailHeight: 212
  },
  {
    src: ImageTen,
    thumbnail: ImageTen,
    thumbnailWidth: 320,
    thumbnailHeight: 174,
  },
  {
    src: ImageEleven,
    thumbnail: ImageEleven,
    thumbnailWidth: 320,
    thumbnailHeight: 212,
  },

  {
    src: "https://res.cloudinary.com/aro-group/image/upload/v1611778269/File_016_x6cazh.jpg",
    thumbnail: "https://res.cloudinary.com/aro-group/image/upload/v1611778269/File_016_x6cazh.jpg",
    thumbnailWidth: 250,
    thumbnailHeight: 212
  },
  {
    src: ImageFour,
    thumbnail: ImageFour,
    thumbnailWidth: 330,
    thumbnailHeight: 212,
  },
  ]

const images = [ImageOne, ImageTwo,
  ImageThree,
  ImageFour,
  ImageFive, ImageSix,
  ImageSeven,
  ImageEight,
  ImageNine,
  ImageTen,
  ImageEleven,
  ImageTwelve,
  ImageThirteen,
]

const GallerySection = () => {
  const [modal, setModal] = useState(false);
  const [img, setImg] = useState('');

  const openModal = (value) => {
    setImg(value)
    setModal(true)
  }

  const modalClose = () => {
    setModal(false);
  }

  return (
    <div className="container" id="gallery">
      <div className="galleryborderBottom">
        <div className="col-12">
          <p className="galleryText"> Gallery</p>
        </div>
        <Gallery maxRows={3} images={IMAGES} />
      </div>
    </div>
  );
};

export default GallerySection;
