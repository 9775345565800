import React from 'react';
import './style.css';

import corevalues from '../../assets/Images/corevalues.png';
import ourvision from '../../assets/Images/ourvision.png';


const aboutusscreenAboutSection = () => {
    return <div className="container">
        <div className="row">
            <div className="col-12">  
                <div className="aboutUsText"> 
                    About us
                </div> 
            </div>
            <div className="col-12">
                <div className="subHeadingAboutSection">
                    Help is Our Main Goal
                </div>
            </div>
            <div className="col-12">
                <div className="TextAboutSection">
                    We believe that every single person on earth deserves access to clean 
                    and safe water, and it is our mission at the ARO Family Foundation  
                    to bring this dream to life for many underserved communities.
                </div>
                <div className="TextAboutSectionTwo">
                    Out of every 3 persons, 1 struggles to have access to clean water. 
                    In most rural areas in Africa, inhabitants have to travel miles to 
                    get access to water that is barely clean. Going through this tedious 
                    routine daily to get water has affected the lives of the inhabitants 
                    in relation to their health, schooling, farming and sanitation among 
                    others. This is why the ARO Family Foundation has set out an ambitious
                     mission to find and enable less affluent communities in Africa to 
                     secure potable water.
                </div>
                <div className="TextAboutSectionThree">
                    About 1 to 2 billion people lack safe drinking water, a problem
                    that causes 30,000 deaths each week. More people die from unsafe
                    water than from war, U.N. Secretary-General Ban Ki-Moon said in 2010.
                </div>
                <div className="TextAboutSectionThree">
                    ARO Family was initially created, still with the goal
                    of providing potable water, to be a channel for its
                    founders to give their Zakat to the less fortunate as 
                    mandated by Islam. However, we took a progressive turn 
                    to use this platform to create awareness and also 
                    provide an easy way for the rest of the world to become a part of this
                    great mission.
                </div>
                <div className="TextAboutSectionFour">
                JOIN US in bringing water closer to those who need them the most! 
                </div>
                <div className="TextAboutSectionFour">
                    The ARO Family Foundation is registered as a 501(c)(3) 
                    Corporation in the state of  Delaware, USA. 
                    All charitable donations are tax deductible.
                </div>
            </div>
            <div className="col-md-6">
                <img className="coreValuesImg" src={corevalues} /> 
            </div>
            <div className="col-md-6 coreValuesSection">
                <div className="coreValuesText">
                    Treat people with respect
                    and if God has blessed you, remember to give back.
                </div>
            </div>
            <div className="orderVisionSection">
                <div className="col-md-6 ourVisionSection">
                    <div className="ourVisionText">
                    A world where everyone has access to potable water
                    </div>
                </div>
                <div className="col-md-6"> 
                    <img className="ourVisionImg" src={ourvision} />
                </div>
            </div>

        </div>
    </div>

};

export default aboutusscreenAboutSection;
