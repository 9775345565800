import React from 'react';
import './style.css';

import FooterLogo from '../../assets/Images/footerlogo.png';
import Twitter from '../../assets/Images/Twitter.png';
import Facebook from '../../assets/Images/Facebook.png';
import Instagram from '../../assets/Images/Instagram.png';
import Youtube from '../../assets/Images/Youtube.png';
import { Link } from 'react-router-dom';

const FooterSection = () => {
  return ( 
    <div className="footerbgColor">
      <div className="container">
        <div className="footerbottomBorder">
          <div className="row">
            <div className="col-12">
              <div className="footerLogoContainer"> 
                <img className="footerLogo" src={FooterLogo} />
              </div>
            </div> 
            <div className="col-12 ">
              <div className="columnDirection">
                <div className="footerlinksContainer"> 
                  <Link to="/aboutus" className="footerlink">
                    About Us
                  </Link>
                  <Link to="/gallery" className="footerlink">
                    Gallery
                  </Link>
                  <Link to="/projects" className="footerlink">
                    Projects 
                  </Link>
                  <Link to="/contact-us" className="footerlink">
                    Contact Us
                  </Link>
                  <Link to="/donate" className="footerlink">
                    Donate
                  </Link> 
                </div>
              </div>
            </div>
            <div className="col-12 ">
              <div className="logosContainer">
                <a href="https://twitter.com/arofoundation" target="_blank">
                  <img className="facebooklogo" src={Twitter} />
                </a> 
                <a href="https://www.facebook.com/ARO-Foundation-539235833686189" target="_blank"> 
                  <img className="facebooklogo" src={Facebook} />
                </a> 
                <a href="https://www.instagram.com/arofamilyfoundation/" target="_blank">
                  <img className="instagramlogo" src={Instagram} />
                </a> 
                <a href="https://www.youtube.com/channel/UCOz-DNHERsWhWs1zPkioQ6g" target="_blank">
                  <img className="youtubelogo" src={Youtube} />
                </a> 
              </div>
            </div>
          </div>
        </div>

        <p className="footerbottomText">© 2021 All rights reserved by ARO Family Foundation</p>
      </div>
    </div>
  );
};

export default FooterSection;
