import React, { useState } from 'react';

import ContactUs from '../components/contactUs/contactUs';
import FooterSection from '../components/footerSection/footerSection';
import ContactHeader from '../components/contacHeader/contactHeader';
import WhiteNavbar from '../components/whiteNavbar/whiteNavbar';
import { contactUsApi } from "../api's/api";
import SuccessModal from '../components/successModal/successModal';

const ContactScreen = () => {
  const [successModal, setSuccessModal] = useState(false);
  const [contactUs, setContactUs] = useState({});
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const data = { ...contactUs };
    data[e.currentTarget.name] = e.currentTarget.value;
    setContactUs(data);
  };

  const handleSubmit = (value) => {
    const params = { fullName: value.Name, email: value.email, message: value.message };
    console.log('value,', value);
    setLoading(true);
    contactUsApi(params)
      .then((res) => {
        console.log('res', res);
        setSuccessModal(true);
        setContactUs({});
        setLoading(false);
      })
      .catch((err) => {
        console.log('err', err);
        setLoading(false);
      });
  };

  return (
    <>
      {successModal ? (
        <SuccessModal visible={successModal} onClose={() => setSuccessModal(false)} successMessage="Message Sent Successfuly" thankuMsg="hank you for contacting to us!" />
      ) : null}
      <ContactHeader />
      <WhiteNavbar />
      <ContactUs contactUs={contactUs} handleChange={handleChange} handleSubmit={handleSubmit} loading={loading} />
      <FooterSection />
    </>
  );
};

export default ContactScreen;
