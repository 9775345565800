import logo from './logo.svg';
import './App.css';
import Routes from './routes';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return <Routes />;
}

export default App;
