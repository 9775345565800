import React from "react";
import { Switch, Route, BrowserRouter as Router } from "react-router-dom";
import HomePage from "./screens/HomePage";
import ContactScreen from "./screens/contactScreen";
import AboutUsScreen from "./screens/aboutusScreen";
import DonatePageEmptyScreen from "./screens/DonatePageEmptyScreen";
import projectscreenone from "./screens/ProjectScreenOne";
import GalleryScreen from "./screens/GalleryScreen";

const Routes = () => {
  return (
    <div className="content">
      <Router>
        <Switch>
          <Route path="/donate" exact component={DonatePageEmptyScreen} />
          <Route path="/about-us" exact component={AboutUsScreen} />
          <Route path="/contact-us" exact component={ContactScreen} />
          <Route path="/gallery" exact component={GalleryScreen} />
          <Route path="/projects" exact component={projectscreenone} />
          <Route path={["/"]} exact component={HomePage} />
        </Switch>
      </Router>
    </div>
  );
};

export default Routes;
