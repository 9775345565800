import React, { useEffect } from 'react';
import GoogleMapReact from 'google-map-react';
import { ClipLoader } from 'react-spinners';
import { Form, Input } from 'antd';
import { ValidationRules } from '../../utils/validationRules';
import './style.css';

const { TextArea } = Input;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
    lg: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 18 },
    lg: { span: 20 },
  },
};

const ContactUs = ({ contactUs, handleChange, handleSubmit, loading }) => {
  const requiredIntegar = [...ValidationRules.required];
  const emailValidation = [...ValidationRules.email];
  const [form] = Form.useForm();
  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(contactUs);
  }, [form, contactUs]);

  return (
    <div className="container" id="contact">
      <div className="row">
        <div className="col-md-6">
          <p className="sayHalloText">Say Hello </p>
          <p className="contactUsText">Contact Us</p>
          <p className="questionsText">Got a question about our work or want to get involved? Drop us a line.</p>
          <Form {...formItemLayout} form={form} onFinish={handleSubmit}>
            <Form.Item rules={requiredIntegar} name="Name">
              <Input placeholder="Full Name" className="fullnameInputfield" />
            </Form.Item>
            <Form.Item rules={emailValidation} name="email">
              <Input placeholder="Email" className="emailInputfield" />
            </Form.Item>
            <Form.Item rules={requiredIntegar} name="message">
              <TextArea placeholder="Message" className="messageinputfield" autoSize={{ minRows: 4, maxRows: 6 }} />
            </Form.Item>
            <button type="submit" className="submitbtnContainer">
              {loading ? <ClipLoader size={20} loading /> : 'Submit Now'}
            </button>
          </Form>
        </div>
        <div className="col-md-6">
          <div className="mapImageContainer">
            <div style={{ height: '40vh', width: '100%' }}>
              <GoogleMapReact
                bootstrapURLKeys={{ key: 'AIzaSyAM0Ij0DnDEnYXOlmMPwBE7f2tdt-wZm1c' }}
                defaultCenter={{
                  lat: 42.3444358,
                  lng: -71.0332479,
                }}
                defaultZoom={11}
              ></GoogleMapReact>
            </div>
          </div>
          <div className="row">
            <div className="col-6 addressContainer">
              <div className="row">
                <div className="firstContainer">
                  <div className="directionColumn">
                    <p className="thirtyWidthfirs">Address :</p>
                    <p className="sixtyWidthfirst">21 Drydock ave, Boston, MA 02210, United States</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-6 emailContainer">
              <div className="row">
                <div className="firstContainer">
                  <div className="directionColumn">
                    <p className="thirtyWidthfourth">E-mail :</p>
                    <p className="sixtyWidthfourth">arofamilyfoundation@gmail.com</p>
                  </div>
                </div>
              </div>

              <div className="row faxContainer">
                <div className="firstContainer">
                  <div className="directionColumn"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
