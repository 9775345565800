import React, { useState } from 'react';

import FooterSection from '../components/footerSection/footerSection';
import Drawers from '../components/drawer/drawer';
import WhiteNavbarShadow from '../components/whitenavbarShadow/whitenavbarShadow';
import DonatePageEmpty from '../components/donatepageEmpty/donatepageEmpty';
import { donateApi } from "../api's/api";
import SuccessModal from '../components/successModal/successModal';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';

const DonatePageEmptyScreen = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState('');
  const [active, setActive] = useState('');
  const [fields, setFields] = useState(true);
  const [data] = useState({});
  const [stripeChange, setStripeChange] = useState(false);

  const onClose = () => {
    setOpenDrawer(false);
  };

  const handleAmount = (value) => {
    setAmount(value);
    setActive(value);
  };

  const handleStripeChange = (value, error) => {
    console.log('value', value);
    console.log('error', error);
    if (error) {
      setStripeChange(true);
      setFields(value);
    } else {
      setStripeChange(false);
      setFields(value);
    }
  };

  const handleAmountChange = (e) => {
    setAmount(e.target.value);
    setActive(e.target.value);
  };

  const handleSubmit = async (value) => {
    console.log('value', value);
    setLoading(true);
    if (!stripe || !elements) {
      return;
    }
    const cardElement = elements.getElement(CardElement);
    console.log('card Elemet', cardElement);
    const params = { fullName: value.Name, email: value.email, amount: parseInt(amount) };
    donateApi(params)
      .then(async (res) => {
        console.log('res', res);
        const result = await stripe.confirmCardPayment(res.data.detail, {
          payment_method: {
            card: cardElement,
            billing_details: { name: params.fullName },
          },
        });
        if (result.error) {
          console.log(result.error.message);
          setStripeChange(false);
          window.alert(result.error.message);
        } else {
          elements.getElement(CardElement).clear();
          setSuccessModal(true);
          setAmount('');
          setActive('');
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log('err', err);
        setLoading(false);
      });
  };

  return (
    <>
      {openDrawer ? <Drawers visible={openDrawer} onClose={onClose} /> : null}
      {successModal ? (
        <SuccessModal
          visible={successModal}
          onClose={() => setSuccessModal(false)}
          successMessage="Donation is Successful"
          thankuMsg="Thank you! Your donation has been successfully recieved"
        />
      ) : null}
      <WhiteNavbarShadow />
      <DonatePageEmpty
        handleAmountChange={handleAmountChange}
        active={active}
        donate={amount}
        handleAmount={handleAmount}
        handleSubmit={handleSubmit}
        loading={loading}
        setFields={setFields}
        fields={fields}
        CardElement={CardElement}
        data={data}
        handleStripeChange={handleStripeChange}
        stripeChange={stripeChange}
      />
      <FooterSection />
    </>
  );
};

export default DonatePageEmptyScreen;
