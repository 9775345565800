import React from 'react';
import './style.css';

import { Link } from 'react-router-dom';
import DonateImage from '../../assets/Images/DonateImage.png';
const DonatetSection = () => {
  return (
    <div className="container"> 
      <div className="row"> 
        <div className="donatebottomborder"> 
          <div className="col-md-6 textfullContainer">
            <div className="textContainer">
              <p className="makeAnImpactText">Make an impact.</p>
              <p className="helpBringText">
                Help bring water closer to the homes of those who need it the most.
              </p>
            </div>
            <button className="donatebtnContainer">
              <Link to="/donate" className="donatebtnText">
                Donate Now
              </Link>
            </button>
          </div>
          <div className="col-md-6"> 
            <div className="donateImageContaner">
              <img className="donateImage" src={DonateImage} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DonatetSection;
