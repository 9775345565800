import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { CgMenuLeft } from "react-icons/cg";
import './style.css';

import HeaderLogo from '../../assets/Images/headerlogo.png';
import WhiteNAvbarLogo from '../../assets/Images/footerlogo.png';

const NavBar = ({ showDrawer }) => {
  const [navColor, setNavColor] = useState(false);
  const changeBackground = () => {
    if (window.scrollY <= 80) {
      setNavColor(true);
    } else setNavColor(false);
  };
  window.addEventListener('scroll', changeBackground);

  return (
    <div className={navColor ? 'navbarFixed' : 'navbarFixed navbarfixedBgcolor'}>
      <div className="container">
        <div className="row navbar">
          <Link to="/" className="col-4">
            <img className="Headerlogo" src={navColor ? WhiteNAvbarLogo : HeaderLogo} />
          </Link>
          <div className="col-8">
            <div className="navbarContainer">
              <Link to="/" className={navColor ? "haederlinks" : "haederlinks_white-bar"}>
                Home
              </Link>
              <Link to="/about-us" className={navColor ? "haederlinks" : "haederlinks_white-bar"}>
                About Us
              </Link>
              <Link to="/gallery" className={navColor ? "haederlinks" : "haederlinks_white-bar"}>
                Gallery
              </Link>
              <Link to="/projects" className={navColor ? "haederlinks" : "haederlinks_white-bar"}>
                Projects
              </Link>
              <Link to="/contact-us" className={navColor ? "haederlinks" : "haederlinks_white-bar"}>
                Contact Us
              </Link>
              <button className="buttonContainer">
                <Link to="/donate" className="buttonText">
                  Donate
              </Link>

              </button>
            </div>
            <div className="navbarNone">
              <div className="btnEnd">
                <button onClick={showDrawer} className="menubtnContainer">
                  <a href="#" className="menubtnText">
                    <CgMenuLeft />
                  </a>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
