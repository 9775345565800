import React from 'react';

import FooterSection from '../components/footerSection/footerSection';
import WhiteNavbar from '../components/whiteNavbar/whiteNavbar';
import ProjectScreenOneProjectSection from '../components/projectscreenoneprojectSection/projectscreenoneprojectSection';
import ProjectScreenOneHeader from '../components/projectscreenoneHeader/projectscreenoneHeader';

const ProjectScreenOne = () => {

  return (
    <>
      
      <WhiteNavbar />
      <ProjectScreenOneHeader />
      <ProjectScreenOneProjectSection />
      <FooterSection />
    </>
  );
};

export default ProjectScreenOne;
