
import React, { useState } from 'react';
import './style.css';

import { CgMenuLeft } from "react-icons/cg";
import {Link} from "react-router-dom";
// import WhiteNavbarLogo from '../../assets/Images/whitenavbarLogo.png';
import HeaderLogo from '../../assets/Images/headerlogo.png';
import Drawers from '../drawer/drawer';

const WhiteNavbar = () => {
    const [openDrawer, setOpenDrawer] = useState(false);
    const showDrawer = () => {
      setOpenDrawer(true);
    };
  
    const onClose = () => {
      setOpenDrawer(false);
    };
    return (
        <div className={'navbarFixed ContactScreennavbarshadowfixedBgcolor'}>
            {openDrawer ? <Drawers visible={openDrawer} onClose={onClose} /> : null}
            <div className="container">
                <div className="row navbar">
                    <div className="col-4">
                    <Link to="/" className="col-4">
                      <img className="Headerlogo" src={HeaderLogo} /> 
                    </Link>
                    </div>
                    <div className="col-8">
                        <div className="navbarshadowContainer"> 
                        <Link to="/" className="whitenavshadowhaederlinks" >
                Home
              </Link>
              <Link to="/about-ys" className="whitenavshadowhaederlinks">
                About Us
              </Link>
              <Link to="/galleryy" className="whitenavshadowhaederlinks">
                Gallery
              </Link> 
              <Link to="/projects" className="whitenavshadowhaederlinks">
                Projects
              </Link>
              <Link to="/contact-us" className="whitenavshadowhaederlinks">
                Contact Us
              </Link>
              <button className="buttonContainershadow">
                <Link to="/donate" className="buttonTextshadow">
                  Donate 
                </Link>
              </button>
                        </div>
                        <div className="navbarNoneshadow">
                            <div className="btnEnd">
                                <button onClick={showDrawer} className="menubtnContainer">
                                    <a href="#" className="menubtnText">
                                    <CgMenuLeft/>
                                    </a>
                                </button>
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WhiteNavbar;
