import React, { useState } from 'react';
import './style.css';

const ContactHeader = ({ showDrawer }) => {
    return <div className="headerbgImage">
        <div className="container"> 
            <div className="row">
                <div className="col-md-8">
                    <div className="textContainer">
                        <p className="contactText">Contact Us</p>
                        <p className="providingText">Providing Access to The Most Basic Essentials of Life</p>
                    </div>
                </div>
                <div className="col-md-4"></div>
            </div>
        </div>
    </div>
 

};

export default ContactHeader;
 