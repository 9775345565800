import React, { useState } from 'react';

import GallerySection from '../components/gallerySection/gallerySection';
import FooterSection from '../components/footerSection/footerSection';
import Drawers from '../components/drawer/drawer';
import WhiteNavbar from '../components/whiteNavbar/whiteNavbar';
import GalleryScreenHeader from '../components/galleryscreenHeader/galleryscreenHeader';

const GalleryScreen = () => {
  const [openDrawer, setOpenDrawer] = useState(false);

  const onClose = () => {
    setOpenDrawer(false);
  };
  return (
    <>
      {openDrawer ? <Drawers visible={openDrawer} onClose={onClose} /> : null} 
      <WhiteNavbar />
      <GalleryScreenHeader />
      <GallerySection />
      <div className="styling-footer">
        <FooterSection /> 
      </div>
    </> 
  );
};

export default GalleryScreen;
