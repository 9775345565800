import React, { useEffect } from 'react';
import './style.css';
import DonatePageEmptyImg from '../../assets/Images/donepageempty.png';
import { ClipLoader } from 'react-spinners';
import { Form, Input, Button } from 'antd';
import { ValidationRules } from '../../utils/validationRules';
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
    lg: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 18 },
    lg: { span: 24 },
  },
};

const DonatepageEmpty = ({
  donate,
  handleAmount,
  handleSubmit,
  loading,
  active,
  handleAmountChange,
  setFields,
  CardElement,
  data,
  fields,
  handleStripeChange,
  stripeChange,
  stripeError,
}) => {
  const requiredIntegar = [...ValidationRules.required];
  const emailValidation = [...ValidationRules.email];
  const positiveValidation = [...ValidationRules.required, ...ValidationRules.positiveInteger];
  const [form] = Form.useForm();
  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(data);
  }, [form, data]);
  return (
    <div className="container">
      <div className="row">
        <div className="col-12 homeDonateLinks">
          <a href="/" className="homeLinkOne">
            Home /
          </a>
          <a href="/donate" className="donatePageLinkTwo">
            Donate page
          </a>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <div className="headingDonatePage">Make an impact.</div>
          <div className="subHeadingDonatePage">Help bring water closer to the homes of those who need it the most.</div>
          <Form {...formItemLayout} form={form} onFinish={handleSubmit}>
            <Form.Item rules={requiredIntegar} name="Name">
              <Input placeholder="Full Name" className="inputFeild" />
            </Form.Item>
            <Form.Item rules={emailValidation} name="email">
              <Input placeholder="Email" className="inputFeild" />
            </Form.Item>
            <div className="btnText">Select or enter an amount</div>
            <div className="packagesDonatePageEmpty">
              <p style={{ cursor: 'pointer' }} onClick={() => handleAmount('50')} className="linkOnePackage m-0 p-0">
                <div style={active === '50' ? { color: 'black', backgroundColor: '#e2af46' } : null} className="packageFiftyDollars">
                  $50
                </div>
              </p>
              <p style={{ cursor: 'pointer' }} onClick={() => handleAmount('100')} className="linkTwoPackage m-0 p-0">
                <div style={active === '100' ? { color: 'black', backgroundColor: '#e2af46' } : null} className="packageHundredDollars">
                  $100
                </div>
              </p>
              <p style={{ cursor: 'pointer' }} onClick={() => handleAmount('250')} className="linkThreePackage m-0 p-0">
                <div style={active === '250' ? { color: 'black', backgroundColor: '#e2af46' } : null} className="packageTwoHundredFiftyDollars">
                  $250
                </div>
              </p>
              <p style={{ cursor: 'pointer' }} onClick={() => handleAmount('300')} className="linkFourPackage m-0 p-0">
                <div style={active === '300' ? { color: 'black', backgroundColor: '#e2af46' } : null} className="packageThreeHundredDollars">
                  $300
                </div>
              </p>
            </div>
            <Form.Item rules={positiveValidation}>
              <Input type="number" required value={donate} onChange={handleAmountChange} placeholder="$" className="inputFeild" />
            </Form.Item>

            <Form.Item rules={positiveValidation}>
              <CardElement id="card-errors" onChange={(e) => handleStripeChange(e.empty, e.error)} className="inputFeild" />
            </Form.Item>
            <p style={{ 'margin-top': '-24px', 'font-size': '12px', color: 'gray' }} className="securly">
              Securely processed by stripe
            </p>
            {stripeError !== '' ? <p style={{ color: 'red' }}>{stripeError}</p> : null}
            <Button disabled={stripeChange || fields || donate === '' ? true : false} htmlType="submit" className="btnTextSubmit">
              {loading ? <ClipLoader size={20} loading /> : 'Submit Now'}
            </Button>
          </Form>
        </div>
        <div className="col-md-6">
          <img className="donateEmptyImg" alt="" src={DonatePageEmptyImg} />
        </div>
      </div>
    </div>
  );
};

export default DonatepageEmpty;
