import React from 'react';
import './style.css';

const AboutHeader = ({ showDrawer }) => {
    return <div className="headerbgImage">
        <div className="container"> 
            <div className="row">
                <div className="col-md-8">
                    <div className="textContainer">
                        <p className="contactText">About Us</p>
                        <p className="providingText">Providing access to the most basic essential need of life - potable water</p>
                    </div>
                </div>
                <div className="col-md-4"></div>
            </div>
        </div>
    </div>
 

};

export default AboutHeader;
 