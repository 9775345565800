import React, { useState } from 'react';
import { HiMenuAlt3 } from 'react-icons/hi';
import { Modal } from 'antd'

import NavBar from '../navBar/navBar';
import Videologo from '../../assets/Images/videologo.png';
import './style.css';

const CoverImage = ({ showDrawer,history }) => {
  const [modal, setModal] = useState(false);
  const openModal = (value) => { 
    setModal(true)
  }
  const modalClose = () => {
    setModal(false);
    window.location.reload();
  }
  return (
    <div className="backgroundimage">
      <NavBar showDrawer={showDrawer} />
      <div className="container">
        <Modal visible={modal} onCancel={modalClose} footer={false} width={1000}>
          <iframe src='https://www.youtube.com/embed/XY_t5zX2b88?autoplay=1&modestbranding=1'            
            allowfullscreen
            width='100%'
            height='500px'
            title='video'
            className='iframe'
          />
        </Modal>
        <div className="row" id="home">
          <div className="col-12">
            <div className="providingContainer">
              <p className="provideText">Providing Access to The Most Basic Essentials of Life</p>
              <div className="videologocontainer">
                <a onClick={openModal}>
                  <img className="videologo" src={Videologo} />
                </a>
                <a className="videolink" onClick={openModal}>
                  Watch our video
                </a>
              </div>
            </div>
          </div> 
        </div>
      </div>
    </div>
  );
};

export default CoverImage;
