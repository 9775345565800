import React from 'react';

import Aboutusimage from '../../assets/Images/Aboutusimage.png';
import './style.css';
  
const AboutUs = () => {
  return (
    <div className="container" id="aboutus">
      <div className="row">
        <div className="bottomBorder">
          <div className="col-md-6"> 
            <p className="aboutusText">About US</p>
            <h2 className="helpgoalHaeding">Help is Our Main Goal</h2>
            <p className="helpgoalfirstParagraph">
              We believe that every single person on earth deserves access to clean and safe water, 
              and it is our mission at the ARO Family Foundation  to bring this dream to
               life for many underserved communities.
            </p>
            <p className="helpgoaltwoParagraph">
              Out of every 3 persons, 1 struggles to have access to clean water. 
              In most rural areas in Africa, inhabitants have to travel miles to get access 
              to water that is barely clean. 
            </p>
            <button className="moreaboutbtnContainer">
              <a href="/about-us" className="moreaboutText">
                More About
              </a>
            </button>
          </div>
          <div className="col-md-6 largeContainer">
            <div className="aboutusRightimage">
              <img className="aboutusImage" src={Aboutusimage} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
