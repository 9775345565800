import axios from 'axios';

const API_ROUTE = 'https://us-central1-arofamilyfoundation-c2149.cloudfunctions.net/api';

export async function contactUsApi(params) {
  return await axios.post(`${API_ROUTE}/contact`, params).then((res) => {
    return res;
  });
}

export async function subscribeApi(params) {
  return await axios.post(`${API_ROUTE}/subscribe`, params).then((res) => {
    return res;
  });
}

export async function donateApi(params) {
  return await axios.post(`${API_ROUTE}/donate`, params).then((res) => {
    return res;
  });
}
