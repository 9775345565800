import React, { Component } from "react";
import { Drawer } from "antd";

import "./style.css";
import { Link } from "react-router-dom";
import { MdClose } from "react-icons/md";

class Drawers extends Component {
  state = {};
  render() {
    return (
      <Drawer
        placement="top"
        closable={false}
        onClose={this.props.onClose}
        visible={this.props.visible}
        className="aqil"
        key="top"
      >
        <div className="container">
          <div className="row" onClick={this.props.onClose}>
            <div className="col-12">
              <div className="rightIcon">
                <MdClose />
              </div>
            </div>
          </div>
        </div>
        <div className="links">
          <Link to="/" className="drawerhaederlinks">
            Home
          </Link>
          <Link to="/about-us" className="drawerhaederlinks">
            About Us
          </Link>
          <Link to="/gallery" className="drawerhaederlinks">
            Gallery
          </Link>
          <Link to="/projects" className="drawerhaederlinks">
            Projects
          </Link>
          <Link to="/contact-us" className="drawerhaederlinks">
            Contact Us
          </Link>
          <button className="buttonContainer">
            <Link to="/donate" className="buttonText">
              Donate
            </Link>
          </button>
        </div>
      </Drawer>
    );
  }
}

export default Drawers;
