import React from 'react';
import './style.css';

import ProjectImage from '../../assets/Images/Projectimage.png';
import CustomSlider from '../customSlider/CustomSlider';

const ProjectSection = () => {
  return (
    <div className="container" id="projects">
      <div className="row"> 
        <div className="projectborderBottom">
          <div className="col-12">
            <p className="projectText">Project</p>  
          </div>  
          <div className="sham">
            <CustomSlider />
          </div>
          <div className="col-12">
            <p className="projectParaOne">
              Katanga is a village about an hour and a half drive from Tamale 
              in the Northern Region of Ghana. It is a home to over 2000 residents, 
              who mostly depend on farming as a livelihood. 
              The people of Katanga travel 25km to the river which is, 
              in fact, a stagnant water. The river is the only source of 
              water for the people and their livestock. 
              Due to their daily activities, fetching water is
               done early in the morning or late in the evening, 
               when the day is dark. And aside the river being unsafe 
               for drinking, residents are exposed to deadly predators in 
               the nearby bushes. Women and children are the ones who are mostly affected.
            </p>
            <p className="projectParaTwo">
              Here are some specific issues they faced, having to travel that far for water:
                 <ul>
                <li>Children mostly run such errands, and that meant they were always late and tired for school.</li>
                <li>Getting water for their houses is time consuming.</li>
                <li>They are prone to poisonous predators that hide in the nearby bushes near the river. </li>
                <li>They are prone to poisonous predators that hide in the nearby bushes near the river. </li>
                 </ul>
            </p>
            <p className="projectParaThree">
              Before the commencement of the project, our initial plan was to provide a manual 
              pumping borehole for the villagers to fetch water from. However, 
              we discovered that a manual pumping approach was not the best option because 
              of the dryness of the land and how deep in the ground water was discovered. 
              Therefore, we chose a better option where ground water will automatically 
              be collected into a water tank, and taps installed to fetch the collected water. 
              The pump has been designed to operate when the water falls below a threshold.
            </p>
            <p className="projectParaThree">
              For most people in Katanga, this water project is their first access to 
              reliable and potable water, and it will be a focal point for 2000+ people.
            </p>
            <p className="projectParaThree">
              It was refreshing to hear directly from the people how happy they are to 
              finally have immediate access to clean and secure water. We are even more 
              encouraged to bring such happiness to many other people and villages.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectSection;
