import React, { useState } from 'react';
import AboutUs from '../components/aboutUs/aboutUs';
import CoverImage from '../components/coverPhoto/coverImage';
import GallerySection from '../components/gallerySection/gallerySection';
import ProjectSection from '../components/projectSection/projectSection';
import DonatetSection from '../components/donateSection/donateSection';
import ContactUs from '../components/contactUs/contactUs';
import SubscribeSection from '../components/subscribeSection/subscribeSection';
import FooterSection from '../components/footerSection/footerSection';
import Drawers from '../components/drawer/drawer';
import { contactUsApi, subscribeApi } from "../api's/api";
import SuccessModal from '../components/successModal/successModal';

const HomePage = (props) => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [thankuMsg, setThankuMsg] = useState('');
  const [contactUs, setContactUs] = useState({});
  const [loading, setLoading] = useState(false);
  const [subscription, setSubscription] = useState('');
  const [subLoading, setSubLoading] = useState(false);

  const showDrawer = () => {
    setOpenDrawer(true);
  };

  const onClose = () => {
    setOpenDrawer(false);
  };

  const handleChange = (e) => {
    const data = { ...contactUs };
    data[e.currentTarget.name] = e.currentTarget.value;
    setContactUs(data);
  };

  const handleSubmit = (value) => {
    const params = { fullName: value.Name, email: value.email, message: value.message };
    console.log('value', value);
    setSuccessMessage('Message Sent Successfuly');
    setThankuMsg('Thank you for contacting to us!');

    setLoading(true);
    contactUsApi(params)
      .then((res) => {
        setSuccessModal(true);
        console.log('res', res);
        setContactUs({});
        setLoading(false);
      })
      .catch((err) => {
        console.log('err', err);
        setLoading(false);
      });
  };

  const handleSubscription = (value) => {
    setSuccessMessage('Successfuly Subscribed');
    setThankuMsg('Thank you for subscribing to us, we will be sure to keep you posted on new project initiatives');

    setSubLoading(true);
    subscribeApi(value)
      .then((res) => {
        setSuccessModal(true);
        console.log('res', res);
        setSubscription('');
        setSubLoading(false);
      })
      .catch((err) => {
        setSubLoading(false);
        console.log(err);
      });
  };

  return (
    <>
      {openDrawer ? <Drawers visible={openDrawer} onClose={onClose} /> : null}
      {successModal ? <SuccessModal visible={successModal} onClose={() => setSuccessModal(false)} successMessage={successMessage} thankuMsg={thankuMsg} /> : null}
      <CoverImage showDrawer={showDrawer} history={props.history} />
      <AboutUs />
      <GallerySection />
      <ProjectSection />
      <DonatetSection />
      <ContactUs contactUs={contactUs} handleChange={handleChange} handleSubmit={handleSubmit} loading={loading} />
      <SubscribeSection subscription={subscription} handleChange={(e) => setSubscription(e.currentTarget.value)} handleSubscription={handleSubscription} subLoading={subLoading} />
      <FooterSection />
    </>
  );
};

export default HomePage;
