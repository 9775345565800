import React from 'react';
import './style.css';

import SubscribebgImage from '../../assets/Images/subscribebgImage.png';
import SubscribeComponent from '../../assets/Images/component.png';
import { ClipLoader } from 'react-spinners';

const SubscribeSection = ({ subscription, handleChange, handleSubscription, subLoading }) => {
  return (
    <div className="subscribebgImage">
      <div className="container">
        <div className="row">
          <div className="12">
            <div className="subscribesectionConatiner">
              <div className="subscribeCompoContainer">
                <img className="subscribecompoImage" src={SubscribeComponent} />
              </div>
              <div className="headinsConatiner">
                <p className="signupText">
                  Sign up for our <span className="updateText">Updates</span>
                </p>
                <p className="enteremailText">Enter your email address to register to our newsletter subscription delivered on a regular basis!</p>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="emailsubsceibebtnContainer">
              <div className="emailbtncontainer">
                <input value={subscription} onChange={handleChange} className="emailInput" type="email" placeholder="Your E-mail" />
              </div>
              <div className="subscribebtncontainer">
                <button onClick={handleSubscription} className="btnbgColor">
                  {subLoading ? <ClipLoader size={20} laoding /> : 'Subscribe'}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscribeSection;
