import React, { useState } from 'react';

import FooterSection from '../components/footerSection/footerSection';
import Drawers from '../components/drawer/drawer';
import AboutHeader from '../components/contacHeader/aboutHeader'
import WhiteNavbar from '../components/whiteNavbar/whiteNavbar';
import AboutusScreenAboutSection from '../components/aboutusscreenAboutSection/aboutusscreenAboutSection';

const AboutUsScreen = () => {
  const [openDrawer, setOpenDrawer] = useState(false);

  const onClose = () => { 
    setOpenDrawer(false);
  };
  return (
    <>
      {openDrawer ? <Drawers visible={openDrawer} onClose={onClose} /> : null}
      <WhiteNavbar/>
      <AboutHeader />
      <AboutusScreenAboutSection />
      <FooterSection />  
    </>
  );
};

export default AboutUsScreen;
